<template>
  <div class="affectation-leads">
    <div class="header">
      <div class="titre">
        <h4>Gestion d'affectation des leads</h4>
      </div>
    </div>
    <hr class="line-leads" />
    <div class="search">
      <span class="searchIcon" title="search">
        <font-awesome-icon icon="search" @click="filter"
      /></span>
      <input
        v-debounce:400="filter"
        class="input-search"
        type="text"
        v-model="full_name"
        placeholder="Rechercher un utilisateur"
        ref="autofocus"
      />
      <div
        v-if="getLeadsLoading"
        class="init-loading three-dots-loading margin-loading"
      >
        Chargement en cours
      </div>
    </div>
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <div v-else class="content-tab-departement">
      <b-table
        small
        show-empty
        id="my-table-departement"
        class="table-user-departement"
        :items="getlistUsers"
        :fields="fields"
        :current-page="page"
        :per-page="0"
        empty-text="Il n'y a aucun enregistrement à afficher"
        empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
      >
        <template v-slot:cell(utilisateur)="data">
          <div class="nom_user">{{ data.item.full_name }}</div>
        </template>
        <template v-slot:cell(departement)="data">
          <Multiselect
            v-model="data.item.departments"
            @input="modifierDepartement(data.item)"
            label="department"
            track-by="id"
            :showLabels="false"
            :closeOnSelect="true"
            :preselect-first="false"
            :allow-empty="true"
            :multiple="true"
            :options="getListDepartement"
            :searchable="true"
            :internal-search="true"
            :max-height="600"
            :loading="getDepartementLoading"
            placeholder="Rechercher département"
          >
            <span slot="noResult">Aucun département trouvé.</span>
            <span slot="noOptions">Aucun département trouvé.</span>
          </Multiselect>
        </template>
      </b-table>
      <div class="pagination">
        <div v-if="getLeadsLoading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <b-pagination
          v-model="page"
          :per-page="per_page"
          :total-rows="usersCountDepartement"
          align="right"
          size="sm"
          prev-text="Précédent"
          next-text="Suivant"
          aria-controls="my-table-departement"
          @change="pagination"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      fields: [
        {
          key: 'utilisateur',
          label: 'Utilisateur',
          thClass: 'width-th',
          tdClass: 'width-th'
        },
        { key: 'departement', label: 'Département' }
      ],
      page: 1,
      per_page: 10,
      full_name: null,
      initLoading: true
    }
  },
  methods: {
    ...mapActions([
      'fetchListUsers',
      'fetchListDepartement',
      'updateDepartement'
    ]),
    modifierDepartement(users) {
      let departement_ids = []
      departement_ids = users.departments.map(d => {
        return d.id
      })
      this.updateDepartement({
        id: users.id,
        department_ids: departement_ids,
        users: users
      })
    },
    pagination(pagination) {
      this.page = pagination
      this.fetchListUsers({
        page: this.page,
        per_page: this.per_page,
        full_name: this.full_name
      })
    },
    filter() {
      this.page = 1
      this.fetchListUsers({
        page: this.page,
        per_page: this.per_page,
        full_name: this.full_name
      })
    }
  },
  computed: {
    ...mapGetters([
      'getlistUsers',
      'getLeadsError',
      'getLeadsLoading',
      'getDepartementLoading',
      'getListDepartement',
      'usersCountDepartement'
    ])
  },
  watch: {
    full_name(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.autofocus) {
            this.$refs.autofocus.focus()
          }
        })
      }
    }
  },
  async mounted() {
    await this.fetchListUsers({ page: this.page, per_page: this.per_page })
    this.fetchListDepartement()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.affectation-leads {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
  }
  .line-leads {
    margin-top: 4px;
    margin-bottom: 7px;
  }
  .search {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    .searchIcon {
      padding: 9px;
      border: 1px solid #d6d8db;
      font-size: 16px;
      background: #d6d8da17;
    }
    .input-search {
      border: 1px solid #dadada;
      outline: none;
      font-size: 16px;
      height: 40px;
      background: #fff;
      padding-left: 10px;
    }
    .margin-loading {
      margin-left: 135px;
    }
  }
  .table-user-departement {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    color: #212529;
    border: 0;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #b9babb;
    margin-top: -8px;
    font-size: 12px;
    tbody {
      .nom_user {
        font-size: 13px;
        text-transform: capitalize;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    .loading {
      width: 50%;
    }
  }
}
</style>
<style lang="scss">
.content-tab-departement {
  .width-th {
    width: 30%;
  }
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(3.3rem / 2) center;
    padding-left: calc(1.3rem + -0.35em);
  }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    vertical-align: middle;
    border: 1px solid #dee2e6;
  }
}
</style>
